<template>
  <div class="mb-0">
    <b-card
      v-if="!isLoaded"
      no-body
      class="elements-center p-5"
    >
      <b-spinner />
    </b-card>
    <template v-else>
      <referrals-banner
        @load-referrals="loadUserReferrals"
      />
      <div
        v-if="hasReferrals && isRetailer"
        class="summary-card"
      >
        <div class="referral-summary">
          <div class="count">
            {{ totalUserReferrals }}
          </div>
          <div class="text">
            Referred Brands
          </div>
        </div>
        <div class="referral-summary">
          <div class="count">
            {{ formatCurrency(potentialCredit, currency) }}
          </div>
          <div class="text">
            Potential Credits
          </div>
        </div>
        <div class="referral-summary">
          <div class="count">
            {{ formatCurrency(usedCredit, currency) }}
          </div>
          <div class="text">
            Earned Credits
          </div>
        </div>
      </div>
      <b-card
        no-body
        class="mt-2"
      >
        <b-table
          v-if="isLoaded"
          ref="referralListTable"
          class="position-relative pb-3 mb-0"
          :per-page="perPage"
          :current-page="currentPage"
          :items="userReferrals"
          responsive
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          empty-text="No referrals found"
        >
          <!--- Column: Referral ID -->
          <template #cell(referralId)="data">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item._id.substring(data.item._id.length - 4) }}
              </span>
            </b-media>
          </template>
          <!-- Column: Referred Email -->
          <template #cell(referredEmail)="data">
            <b-media vertical-align="center">
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.referredEmail }}
              </span>
            </b-media>
          </template>
          <!-- Column: Created At -->
          <template #cell(createdAt)="data">
            <div class="text-nowrap">
              {{ getDateFromTimestamp(data.item.createdAt) }}
            </div>
          </template>
          <template #head(createdAt)>
            <div class="text-nowrap">
              Referred on
            </div>
          </template>
          <template #head(referralId)>
            <div class="text-nowrap">
              Referral Id
            </div>
          </template>
          <template #head(updatedAt)>
            <div class="text-nowrap">
              Last updated
            </div>
          </template>
          <template #cell(updatedAt)="data">
            <div class="text-nowrap">
              {{ getDateFromTimestamp(data.item.updatedAt) }}
            </div>
          </template>
          <!-- Column: Status -->
          <template #cell(status)="data">
            <span
              :class="parseStatusVariant(data.item.status)"
              class="text-capitalize table-status"
            >
              {{ data.item.status }}
            </span>
          </template>
          <template #cell(followUps)="data">
            <div
              v-if="data.item.followUp"
              class="d-flex flex-column align-items-center mt-50"
            >
              <div class="d-flex">
                <span
                  v-for="count in 5"
                  :key="count"
                  :class="count <= data.item.followUps ? 'info' : ''"
                  class="rounded-count-icon"
                />
              </div>
              <span
                v-if="data.item.lastFollowedUpDays !== null"
                class="text-nowrap font-weight-normal color-dark-gray mt-50"
              >
                Followed up
                <span v-if="data.item.lastFollowedUpDays === 0"> today</span>
                <span v-else> {{ data.item.lastFollowedUpDays }} day{{ data.item.lastFollowedUpDays > 1 ? 's': '' }} ago </span>
              </span>
            </div>
          </template>
          <template #cell(followUp)="data">
            <b-button
              v-if="data.item.followUp"
              variant="custom-secondary"
              class="text-nowrap"
              :disabled="data.item.followUps >= 5"
              @click="openReferralMailPopup(data.item)"
            >
              Follow up
            </b-button>
          </template>
        </b-table>
        <div
          v-if="hasPagination"
          class="mx-2 mb-2 mt-auto"
        >
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUserReferrals"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <referral-mail
        :is-follow-up="true"
        :referral-data="referralData"
        @load-referrals="loadUserReferrals"
      />
    </template>
    <tool-kit :toolkit-page="TOOLKIT_PAGE.REFERRALS" />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BMedia,
  BTable,
  BPagination,
  BSpinner,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { getDateFromTimestamp } from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import constants, { TOOLKIT_PAGE } from '@/constants'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import { formatObject, getElapsedDaysCount } from '@/@core/utils/utils'
import store from '@/store'
import { GET_REFERRALS_LIST } from '@/store/modules/referral.module'
import { GET_REFERRALS_TABLE_COLUMNS } from '@/table-columns-constants'
import ReferralsBanner from './ReferralsBanner.vue'
import UserRoleMixin from '../UserRoleMixin.vue'
import ReferralMail from './ReferralMail.vue'

const { formatCurrency } = utils

const { USER_REFERRAL_STATUS } = c

export default {
  name: 'Referrals',
  components: {
    BCard,
    BRow,
    BCol,
    BMedia,
    BTable,
    BPagination,
    BSpinner,
    FeatherIcon,
    ReferralsBanner,
    BButton,
    ReferralMail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      isLoaded: false,
      userReferrals: [],
      currentPage: 1,
      perPage: 10,
      totalUserReferrals: 0,
      tableColumns: GET_REFERRALS_TABLE_COLUMNS(),
      getDateFromTimestamp,
      showReferralModel: false,
      USER_REFERRAL_STATUS,
      TOOLKIT_PAGE,
      // This variable sends current data for sending mail through follow up
      referralData: {},
      formatCurrency,
      potentialCredit: 0,
      usedCredit: 0,
      currency: '',
    }
  },
  computed: {
    hasPagination() {
      return this.totalUserReferrals >= 10
    },
    hasReferrals() {
      return this.totalUserReferrals > 0
    },
  },
  created() {
    this.loadUserReferrals()
  },
  methods: {
    loadUserReferrals(isEmitsFromReferralModel = false) {
      store.dispatch(GET_REFERRALS_LIST).then(res => {
        this.userReferrals = res.data.data.referrals
        this.potentialCredit = res.data.data.potentialCredit
        this.usedCredit = res.data.data.usedCredit
        this.currency = res.data.data.currency
        this.userReferrals.forEach(referral => {
          referral.followUp = this.isFollowUpEnabled(referral.status)
          referral.lastFollowedUpDays = getElapsedDaysCount(referral?.lastFollowUpDate)
        })
        const hasFollowUp = this.userReferrals.some(
          referral => referral.followUp,
        )
        if (hasFollowUp) {
          this.tableColumns.push(
            { key: 'followUps', label: '' },
            { key: 'followUp', label: '' },
          )
        }
        this.referralData = this.userReferrals[0]
        this.totalUserReferrals = this.userReferrals.length
        this.isLoaded = true
        if (!isEmitsFromReferralModel) {
          analytics.track(
            this.isBrand
              ? constants.TRACKS.PAGES.BRAND_OPENS_REFERRAL_PAGE
              : constants.TRACKS.PAGES.RETAILER_OPENS_REFERRAL_PAGE,
            formatObject({ noOfReferrals: this.totalUserReferrals }),
          )
        }
      })
    },
    parseStatusVariant(status) {
      switch (status) {
        case USER_REFERRAL_STATUS.PENDING:
          return 'pending'
        case USER_REFERRAL_STATUS.EXPIRED:
        case USER_REFERRAL_STATUS.REJECTED:
          return 'rejected'
          case USER_REFERRAL_STATUS.APPROVED:
          case USER_REFERRAL_STATUS.ACTIVE:
          return 'confirmed'
        default:
          return 'new'
      }
    },
    parseTooltipMessage(status) {
      if (this.isBrand) {
        switch (status) {
          case USER_REFERRAL_STATUS.PENDING:
            return 'Invitation is waiting for approval'
          case USER_REFERRAL_STATUS.EXPIRED:
            return 'Zero commission offer expires in 30 days after referred retailer signs up'
          case USER_REFERRAL_STATUS.REJECTED:
            return 'We are sorry, but this retailer can not be registered on Kingpin'
          default:
            return 'You get zero commission on the first order from this retailer'
        }
      } else {
        switch (status) {
          case USER_REFERRAL_STATUS.PENDING:
            return 'Invitation is waiting for approval'
          case USER_REFERRAL_STATUS.EXPIRED:
            return 'Rewards from this brand have been collected'
          case USER_REFERRAL_STATUS.REJECTED:
            return 'We are sorry, but this brand can not be registered on Kingpin'
          case USER_REFERRAL_STATUS.ACTIVE:
            return 'You are receiving rewards from this brand'
          default:
            return 'Invite accepted, waiting for the brand to upload their catalog'
        }
      }
    },
    isFollowUpEnabled(status) {
      return [
        USER_REFERRAL_STATUS.ACTIVE,
        USER_REFERRAL_STATUS.APPROVED,
        USER_REFERRAL_STATUS.PENDING,
      ].includes(status)
    },
    openReferralMailPopup(referralData) {
      this.referralData = referralData
      this.$bvModal.show('send-referral-mail')
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/pages/app-referral.scss';
</style>
