<template>
  <b-modal
    :id="modalId"
    modal-class="email-notification-model modal-add-user"
    centered
    no-close-on-backdrop
    no-close-on-esc
    :hide-header-close="!isFollowUp"
    size="lg"
    hide-footer
    header-class="border-0"
    header-bg-variant="light"
    @show="showModal"
  >
    <div class="modal-add-user-body mb-2">
      <div class="modal-add-user-header mb-75">
        Send a message
      </div>
      <div class="notification-block scroll-bar">
        <template v-if="showForm">
          <div v-show="!showExpandedView">
            <div class="header">
              {{ headerText }}
            </div>
            <div class="my-1">
              <b-badge class="notification-badge">
                {{ referralData.email }}
                <feather-icon
                  icon="XIcon"
                  size="16"
                  class="ml-50"
                />
              </b-badge>
            </div>
            <validation-provider
              #default="{ errors }"
              name="Subject"
              vid="name"
              rules="min:5"
            >
              <b-form-group label-class="color-neutral-black">
                <template #label>
                  Subject <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="subject"
                  placeholder="Type in your message subject"
                />
                <span
                  v-if="errors.length"
                  class="text-danger font-small-4"
                >{{ errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </div>
          <div
            v-show="showExpandedView"
            class="mb-1"
          >
            <b-button
              variant="outline-primary"
              @click="showExpandedView = false"
            >
              <feather-icon icon="ChevronLeftIcon" />
              Back
            </b-button>
          </div>
          <b-form-group
            label-class="color-neutral-black"
            class="position-relative"
          >
            <template #label>
              Message <span class="text-danger">*</span>
            </template>
            <vue-editor
              v-model="message"
              :editor-toolbar="VUE_EDITOR_DEFAULT_EDIT_OPTIONS"
              placeholder="Type in your message"
              :class="showExpandedView ? 'expanded' : ''"
            />
            <span
              v-if="message && hasValidMessage"
              class="text-danger font-small-4"
            >Message should be atleast 20 characters</span>
            <span
              v-show="!showExpandedView"
              class="expand-icon"
              @click="showExpandedView = true"
            >
              <expand-icon />
            </span>
          </b-form-group>
        </template>
      </div>

      <div
        v-show="!showExpandedView && showForm"
        class="d-flex align-items-center justify-content-end my-1"
      >
        <template v-if="isFollowUp">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="closeNotificationModal"
          >
            Cancel
          </b-button>
          <b-button
            :disabled="!validateInput || isLoading"
            variant="custom-primary"
            @click="sendBroadcastMessage"
          >
            {{ isLoading ? 'Sending...' : 'Send' }}
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="outline-primary"
            class="mr-1"
            :disabled="isSaving || isLoading"
            @click="skipPersonalisation"
          >
            Skip Personalisation
          </b-button>
          <b-button
            :disabled="!validateInput || isLoading || isSaving"
            variant="custom-primary"
            @click="sendPersonalisationMessage"
          >
            {{ isLoading ? 'Sending...' : 'Send' }}
          </b-button>
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import store from '@/store'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { VueEditor } from 'vue2-editor'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ExpandIcon from '@/@core/assets/svg-components/ExpandIcon.vue'
import { formatObject, removeHtmlTags } from '@/@core/utils/utils'
import analytics, {
  getSelectionOrWishlistMixpanelObj,
} from '@/@core/utils/analytics'
import constants, { VUE_EDITOR_DEFAULT_EDIT_OPTIONS } from '@/constants'
import { emailTemplates } from '@kingpin-global/kingpin-utils-frontend'
import {
  REFERRAL_INVITE,
  SEND_REFERRAL_MAIL,
} from '@/store/modules/referral.module'
import UserRoleMixinVue from '../UserRoleMixin.vue'

export default {
  components: {
    BModal,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BButton,
    VueEditor,
    BBadge,
    FeatherIcon,
    ExpandIcon,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixinVue],
  props: {
    isFollowUp: {
      type: Boolean,
      default: false,
    },
    referralData: {
      type: Object,
      default() {
        return {}
      },
    },
    modalId: {
      type: String,
      default: 'send-referral-mail',
    },
    isSelection: {
      type: Boolean,
      default: false,
    },
    wishlist: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      subject: '',
      message: '',
      showDropdown: false,
      showExpandedView: false,
      showForm: true,
      isLoading: false,
      VUE_EDITOR_DEFAULT_EDIT_OPTIONS,
      isSaving: false,
    }
  },
  computed: {
    validateInput() {
      const isValid = !(this.subject.length < 5) && !this.hasValidMessage
      return isValid
    },
    hasValidMessage() {
      return removeHtmlTags(this.message).length < 20
    },
    profileData() {
      return store.getters.profile
    },
    headerText() {
      if (this.isFollowUp) {
        return 'Your partner will receive this message from your specifically created Kingpin email. The replies will be received by Aquisition team and proceeded carefully and respectfully.'
      }
      return 'This message will be shared through the platform. Any responses will be processed by our team accordingly'
    },
  },
  emits: ['load-referrals'],
  methods: {
    sendBroadcastMessage() {
      this.isLoading = true
      const payload = {
        subject: this.subject,
        body: this.message,
        referralId: this.referralData._id,
      }
      if (this.isFollowUp) {
        payload.followUp = this.isFollowUp
      }
      store
        .dispatch(SEND_REFERRAL_MAIL, payload)
        .then(res => {
          this.isLoading = false
          apiToastSuccess(res.data.message)
          analytics.track(
            this.isBrand
              ? constants.TRACKS.ACTIONS.BRAND_SENDS_REFERRAL_REMINDER
              : constants.TRACKS.ACTIONS.RETAILER_SENDS_REFERRAL_REMINDER,
            this.trackFollowUpData(true),
          )
          this.$emit('load-referrals', true)
          this.$bvModal.hide(this.modalId)
        })
        .catch(err => {
          this.isLoading = false
          apiToastError(err)
        })
    },

    resetForm() {
      this.subject = ''
      this.message = ''
      this.showForm = true
      this.isLoading = false
    },
    closeNotificationModal() {
      if (this.isFollowUp) {
        analytics.track(
          this.isBrand
            ? constants.TRACKS.ACTIONS.BRAND_CLOSES_REFERRAL_REMINDER_FORM
            : constants.TRACKS.ACTIONS.RETAILER_CLOSES_REFERRAL_REMINDER_FORM,
          this.trackFollowUpData(),
        )
      }
      this.$bvModal.hide(this.modalId)
    },
    showModal() {
      this.resetForm()
      let emailTemplate = {}
      if (this.isFollowUp) {
        if (this.isRetailer) {
          emailTemplate = emailTemplates.REFERRAL_FOLLOWUP_RETAILER_TO_BRAND(
            this.referralData.firstName,
            this.profileData.firstName,
            this.profileData.entityName,
          )
        } else {
          emailTemplate = emailTemplates.REFERRAL_FOLLOWUP_BRAND_TO_RETAILER(
            this.referralData.firstName,
            this.profileData.firstName,
            this.profileData.entityName,
          )
        }
        this.subject = emailTemplate?.subject
        this.message = emailTemplate?.body.replaceAll('              ', '')
        analytics.track(
          this.isBrand
            ? constants.TRACKS.ACTIONS.BRAND_OPENS_REFERRAL_REMINDER_FORM
            : constants.TRACKS.ACTIONS.RETAILER_OPENS_REFERRAL_REMINDER_FORM,
          this.trackFollowUpData(),
        )
      } else if (this.isRetailer) {
        emailTemplate = emailTemplates.REFERRAL_CUSTOMIZABLE_RETAILER_TO_BRAND(
          this.referralData.entityName,
          this.profileData.entityName,
          this.referralData.firstName,
          this.profileData.firstName,
        )
      } else {
        emailTemplate = emailTemplates.REFERRAL_CUSTOMIZABLE_BRAND_TO_RETAILER(
          this.profileData.entityName,
          this.referralData.firstName,
          this.profileData.firstName,
        )
      }
      this.subject = emailTemplate?.subject
      this.message = emailTemplate?.body.replaceAll('              ', '')
    },
    trackFollowUpData(includeMessage = false) {
      let trackObj = {}
      let profileObj = {}
      let formDataObj = {}
      trackObj = formatObject({
        referralStatus: this.referralData.status,
        noOfSentReminders: this.referralData.followUps,
        referredContactEmail: this.referralData?.email,
      })
      if (this.isBrand) {
        profileObj = formatObject({
          brandEntityName: this.profileData?.entityName,
          brandEmail: this.profileData.email,
        })
      } else {
        profileObj = formatObject({
          retailerEntityName: this.profileData?.entityName,
          retailerEmail: this.profileData.email,
        })
      }
      if (includeMessage) {
        formDataObj = {
          message: removeHtmlTags(this.message),
          subject: this.subject,
        }
      }
      trackObj = {
        ...trackObj,
        ...profileObj,
        ...formDataObj,
      }
      return trackObj
    },
    skipPersonalisation() {
      this.isSaving = true
      this.sendInvite()
    },
    sendPersonalisationMessage() {
      const payload = {
        subject: this.subject,
        body: this.message,
      }
      this.isLoading = true
      let trackObj = formatObject({
        referredContactEmail: this.referralData.email,
        subject: this.subject,
        body: removeHtmlTags(this.message),
      })
      let profileObj = {}
      if (this.isBrand) {
        profileObj = formatObject({
          brandEntityName: this.profileData?.entityName,
          brandEmail: this.profileData.email,
        })
      } else {
        profileObj = formatObject({
          retailerEntityName: this.profileData?.entityName,
          retailerEmail: this.profileData.email,
        })
      }
      trackObj = {
        ...trackObj,
        ...profileObj,
      }
      analytics.track(
        this.isBrand
          ? constants.TRACKS.ACTIONS.BRAND_SENDS_PERSONAL_INVITATION
          : constants.TRACKS.ACTIONS.RETAILER_SENDS_PERSONAL_INVITATION,
        formatObject(trackObj),
      )
      this.sendInvite(payload)
    },
    sendInvite(payload = {}) {
      payload = {
        ...payload,
        email: this.referralData.email,
        firstName: this.referralData.firstName,
        lastName: this.referralData.lastName,
        entityName: this.referralData.entityName,
      }
      if (this.referralData.phNumber) {
        payload.phoneNumber = Number(this.referralData.phNumber)
      }
      if (this.referralData.website) {
        payload.webSite = this.referralData.website
      }
      if (this.isSelection) {
        payload.isReferralFromSelection = true
      }
      store
        .dispatch(REFERRAL_INVITE, payload)
        .then(() => {
          this.isLoading = false
          this.isSaving = false
          this.$emit('load-referrals', true)
          this.trackInviteSent()
          this.$bvModal.hide('referral-personalise-message')
          this.$bvModal.show('referral-success-popup')
        })
        .catch(err => {
          apiToastError(err)
          this.isLoading = false
          this.isSaving = false
          const errorMessage = parseErrorObject(err) || 'User is not referred'
          analytics.track(
            this.isBrand
              ? constants.TRACKS.ACTIONS.BRAND_INVITE_SENT
              : constants.TRACKS.ACTIONS.RETAILER_INVITE_SENT,
            formatObject({ status: 'Error', errorMessage }),
          )
        })
    },
    trackInviteSent() {
      if (this.isSelection) {
        analytics.track(
          constants.TRACKS.ACTIONS.BRAND_SHARES_SELECTION_WITH_REFERRAL,
          formatObject(getSelectionOrWishlistMixpanelObj(this.wishlist, true)),
        )
      }
      analytics.track(
        this.isBrand
          ? constants.TRACKS.ACTIONS.BRAND_INVITE_SENT
          : constants.TRACKS.ACTIONS.RETAILER_INVITE_SENT,
        formatObject({ status: 'Success' }),
      )
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-notification.scss';
</style>
