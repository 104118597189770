<template>
  <b-modal
    id="referral-commercial-terms-popup"
    centered
    hide-footer
    modal-class="referral-modal modal-add-user"
    header-bg-variant="light"
  >
    <div class="modal-add-user-body mb-2">
      <div class="modal-add-user-header">
        Provide Current Terms
      </div>
      <validation-observer
        ref="collectionForm"
        #default="{ invalid }"
      >
        <div class="mt-2">
          <validation-provider
            #default="{ errors }"
            vid="commercial-terms"
            name="Commercial"
            rules="required|between:0,100"
          >
            <b-form-group>
              <template
                #label
              >Commercial Terms <span class="text-danger">*</span></template>
              <b-form-input
                id="commercial-terms"
                v-model="commercialTerms"
                name="commercial-terms"
                placeholder="Commercial Terms"
                required
                :state="errors.length > 0 ? false : null"
              />
              <small
                v-if="errors.length"
                class="text-danger"
              >
                Commercial terms should be 0 to 100

              </small>
            </b-form-group>
          </validation-provider>
        </div>
        <b-form-group>
          <template #label>
            Payment Terms <span class="text-danger">*</span>
          </template>
          <b-dropdown
            class="w-100 referral-dropdown"
            variant="outline-none"
            no-caret
            :toggle-class="!paymentTerms ? 'color-grey-sult' : 'color-body-color'"
          >
            <template #button-content>
              {{
                paymentTerms ? PAYMENT_TERMS[paymentTerms].TEXT : 'Payment Terms'
              }}
            </template>
            <b-dropdown-item
              v-for="paymentTerm of Object.keys(PAYMENT_TERMS)"
              :key="paymentTerm"
              :active="paymentTerms === paymentTerm"
              @click="paymentTerms = paymentTerm"
            >
              {{ PAYMENT_TERMS[paymentTerm].TEXT }}
            </b-dropdown-item>
          </b-dropdown>
        </b-form-group>
        <b-form-group label="Discount">
          <b-form-input
            id="discount"
            v-model="discount"
            name="discount"
            placeholder="Discount"
            :state="isValidDiscount ? false : null"
            @blur="addDiscountPercentage"
          />
          <small
            v-if="isValidDiscount"
            class="text-danger"
          > Discount should be 1 to 100</small>
        </b-form-group>
        <div class="w-100 mt-1 d-flex flex-wrap">
          <b-form-group
            :class="proofOfOrders ? 'w-100' : 'w-50'"
            label-class="pb-0 color-neutral-black"
          >
            <template #label>
              Proof of Orders
            </template>
            <div class="color-actions-text-grey">
              Shipment detail, Invoice etc
            </div>
          </b-form-group>
          <b-form-group
            v-if="!proofOfOrders"
            class="w-50 d-flex align-items-center justify-content-end"
            label-for="secure-type"
            label-class="mb-0 w-75"
          >
            <template #label>
              <div class="btn d-flex align-items-center btn-outline-info w-75 float-right">
                <upload-icon class="upload-icon-svg" />
                Upload
              </div>
            </template>
            <b-form-file
              id="secure-type"
              v-model="proofOfOrders"
              plain
              hidden="true"
            />
          </b-form-group>
          <div
            v-if="proofOfOrders"
            class="uploaded-file-referral w-100"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <b-img
                  :src="fileicon"
                  alt="ErrorIcon"
                  class="file-icon"
                />
                <div class="file-name-size ml-1">
                  <div class="text-truncate">
                    {{ textTruncate(proofOfOrders.name) }}
                  </div>
                  <div>
                    Size: {{ formatNumber(proofOfOrders.size / 1024, 2) }} KB
                  </div>
                </div>
              </div>
              <div @click="proofOfOrders = null">
                <feather-icon
                  class="text-danger cursor-pointer"
                  icon="XIcon"
                  size="20"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end my-1">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="$bvModal.hide(REFERRAL_COMMERCIAL_TERMS_MODAL_ID)"
          >
            Close
          </b-button>
          <b-button
            variant="custom-primary"
            :disabled="isSaving || invalid || isValid"
            @click="updateReferralWalletRule"
          >{{ isSaving ? 'Saving' : 'Save' }}
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BButton,
  BFormGroup,
  BFormFile,
  BDropdown,
  BDropdownItem,
  BImg,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import UploadIcon from '@/@core/assets/svg-components/UploadIcon.vue'
import { formatNumber, formatObject, textTruncate } from '@/@core/utils/utils'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { between, required } from 'vee-validate/dist/rules'
import store from '@/store'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import { UPDATE_REFERRAL_WALLET_RULE } from '@/store/modules/referral.module'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import UserRoleMixin from '../UserRoleMixin.vue'

const { FILE_TYPES, PAYMENT_TERMS } = c

const REFERRAL_COMMERCIAL_TERMS_MODAL_ID = 'referral_commercial_terms_modal'

export default {
  name: 'ReferralCommercialTerms',
  components: {
    BModal,
    BButton,
    FeatherIcon,
    BFormInput,
    UploadIcon,
    BFormGroup,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BImg,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      commercialTerms: null,
      discount: '',
      paymentTerms: null,
      proofOfOrders: null,
      textTruncate,
      formatNumber,
      PAYMENT_TERMS,
      isSaving: false,
      between,
      required,
      fileicon: require('@/assets/images/icons/fileicon.svg'),
      REFERRAL_COMMERCIAL_TERMS_MODAL_ID,
    }
  },
  computed: {
    isValidDiscount() {
      if (!this.discount) {
        return false
      }
      const discount = this.discount.replaceAll('%', '')
      return !(!isNaN(discount) && discount >= 1 && discount <= 100)
    },
    isValid() {
      return this.isValidDiscount || !this.paymentTerms
    },
    profileData() {
      return store.getters.profile
    },
  },
  methods: {
    addDiscountPercentage() {
      this.discount = this.discount.replaceAll('%', '')
      if (this.discount && !isNaN(this.discount)) {
        this.discount += '%'
      }
    },
    async updateReferralWalletRule() {
      this.isSaving = true
      let proofOfOrderLink = null
      if (this.proofOfOrders) {
        proofOfOrderLink = await store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.REFERRAL_ORDER_PROOF, file: this.proofOfOrders })
      }
      const payload = {
        email: this.email,
        walletRule: {
          commercialTerms: Number(this.commercialTerms),
          paymentTerms: PAYMENT_TERMS[this.paymentTerms].TEXT,
        },
      }
      if (proofOfOrderLink) {
        payload.walletRule.proofOfOrders = proofOfOrderLink
      }
      if (this.discount && !this.isValidDiscount) {
        const discount = this.discount.replaceAll('%', '')
        payload.walletRule.discount = Number(discount)
      }
      store.dispatch(UPDATE_REFERRAL_WALLET_RULE, payload)
      .then(() => {
        apiToastSuccess('Information was saved')
        const trackObj = {
          referredContactEmail: this.email,
          brandEntityName: this.profileData?.entityName,
        }
        analytics.track(constants.TRACKS.ACTIONS.BRAND_ADDS_WALLET_RULE, formatObject(trackObj))
        this.isSaving = false
        this.$bvModal.hide(REFERRAL_COMMERCIAL_TERMS_MODAL_ID)
      })
      .catch(err => {
        this.isSaving = false
        apiToastError(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
</style>
