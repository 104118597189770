<template>
  <div>
    <b-row class="referal-banner mx-0">
      <b-col class="col-12 col-lg-7 referral-banner-bg">
        <div>
          <b-card-text
            v-if="isRetailer"
            class="referal-header"
          >
            <span
              class="lg"
            >Earn up to <br>
              <span>$5,000</span> </span><br>
            <span>
              For every new brand you invite to join <br>Kingpin, earn up to $5,000 in credits that <br>can be used towards your orders
            </span>
          </b-card-text>
          <b-card-text
            v-if="isBrand"
            class="referal-header"
          >
            <span
              class="lg"
            >Zero <br>
              <span>commission</span> </span><br>
            <span>
              on the first order with any of your <br> invited retail partners
            </span>
          </b-card-text>
          <referrals-invite
            is-referral-page
            @load-referrals="loadReferrals"
          />
        </div>
      </b-col>
      <b-col class="col-12 col-lg-5 qualify-block">
        <b-img
          :src="logo"
          :alt="logo"
          class="logo-img"
        />
        <div class="font-size-16-px mx-3 mt-2 mb-1 pt-3">

          <div class="modal-referral-heading mb-2">
            How does the Referral program work?
          </div>
          <div v-if="isBrand">
            <div class="qualify-list">
              <div>
                <span class="list-style"> 1. </span>
              </div>
              <div class="list-element">
                Invite a retailer via the Referrals dashboard
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 2. </span>
              </div>
              <div class="list-element">
                For every new Retailer you refer, you will receive ZERO commission on their first order placed within 30 days of signing up
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 3. </span>
              </div>
              <div class="list-element">
                Keep track of your savings via the Savings tab in your
                <b-link
                  class="color-blue"
                  :to="{ name: 'savings' }"
                >
                  Wallet dashboard
                </b-link>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 1. </span>
              </div>
              <div class="list-element">Invite a brand via the Referrals dashboard
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 2. </span>
              </div>
              <div class="list-element">Receive an immediate credit of $250 as soon as the brand registers and goes live on Kingpin
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 3. </span>
              </div>
              <div class="list-element">Earn an additional $250 in credit for every order placed by the brand within 60 days of going live
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 4. </span>
              </div>
              <div class="list-element">Monitor your rewards progress on the Rewards tab in your
                <b-link
                  class="color-blue"
                  :to="{ name: 'rewards' }"
                >
                  Wallet dashboard
                </b-link>
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 5. </span>
              </div>
              <div class="list-element">Apply your credits towards any future order, regardless of the brand or order value
              </div>
            </div>
          </div>
          <div
            class="mt-1 terms-text"
            @click="onClickTerms"
          >
            Terms and conditions
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      ref="referalTerms"
      header-bg-variant="light"
      centered
      footer-class="border-0 mx-3 mb-2"
      ok-only
      ok-variant="custom-primary"
      ok-title="Close"
      modal-class="referral-terms"
    >
      <div class="font-size-16-px mx-3 mt-1">
        <div class="modal-referral-heading">
          Terms and conditions
        </div>
        <div>
          <template v-if="isBrand">
            <div class="qualify-list">
              <div>
                <span class="list-style"> 1. </span>
              </div>
              <div class="list-element">
                No limit on the number of retailers you may refer.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 2. </span>
              </div>
              <div class="list-element">
                Offer does not apply to brands which are already part of the
                Kingpin platform or that have been successfully referred by
                another retailer.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 3. </span>
              </div>
              <div class="list-element">
                The referral program for a particular retailer expires 30 days
                after their registration on Kingpin.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 4. </span>
              </div>
              <div class="list-element">
                Zero commission only applies on the first order from a referred
                retailer.
              </div>
            </div>
          </template>
          <template v-else>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 1. </span>
              </div>
              <div class="list-element">
                No limit on the number of brands you may refer.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 2. </span>
              </div>
              <div class="list-element">
                The offer does not apply to brands that (a.) are already
                registered on Kingpin or (b.) have been referred by another
                retailer.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 3. </span>
              </div>
              <div class="list-element">
                Each additional $250 is attributed to your account upon the
                brand receiving their <u> first order from any retailer.</u> For
                example, when 2 retailers place a few orders each, only $500
                ($250 x 2) is applied.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 4. </span>
              </div>
              <div class="list-element">
                The maximum reward per referred brand is $5,000.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 5. </span>
              </div>
              <div class="list-element">
                Rewards will be received in credit points, every credit point
                equals 1 USD.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 6. </span>
              </div>
              <div class="list-element">
                Referral program for a particular brand expires 60 days after
                their first catalog is published.
              </div>
            </div>
            <div class="qualify-list">
              <div>
                <span class="list-style"> 7. </span>
              </div>
              <div class="list-element">
                Reward credit points can not be exchanged or transferred to other
                accounts.
              </div>
            </div>
          </template>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BModal,
  BLink,
  BImg,
} from 'bootstrap-vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { invitingUserRole } from '@/@core/utils/utils'
import UserRoleMixinVue from '../UserRoleMixin.vue'
import ReferralsInvite from './ReferralsInvite.vue'

export default {
  components: {
    BRow,
    BCardText,
    BCol,
    BModal,
    BLink,
    BImg,
    ReferralsInvite,
  },
  mixins: [UserRoleMixinVue],
  data() {
    return {
      invitingUserRole,
      logo: require('@/assets/images/logo/kingpin-logo-with-text.svg'),
    }
  },
  emits: ['load-referrals'],
  methods: {
    trackEvent(event) {
      analytics.track(event)
    },
    onClickTerms() {
      this.$refs.referalTerms.show()
      this.trackEvent(
        this.isBrand
          ? constants.TRACKS.ACTIONS.BRAND_READS_REFERRALS_TERMS
          : constants.TRACKS.ACTIONS.RETAILER_READS_REFERRALS_TERMS,
      )
    },
    loadReferrals() {
      this.$emit('load-referrals', true)
    },
  },
}
</script>
