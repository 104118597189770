<template>
  <div class="d-inline-block">
    <b-button
      id="intro_referral_button"
      :variant="isReferralPage ? 'light': 'success'"
      class="font-weight-bolder"
      @click="openReferralModal"
    >
      <feather-icon
        v-if="!isReferralPage"
        icon="UserPlusIcon"
      /> Invite a <span class="text-capitalize">{{ invitingUserRole() }}</span>
    </b-button>
    <referral-model
      @load-referrals="$emit('load-referrals', $event)"
    />
  </div>
</template>

<script>
import {
 BButton, VBModal,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ReferralModel from '@/views/apps/referral/referralModel.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { invitingUserRole } from '@/@core/utils/utils'

export default {
  components: {
    BButton,
    FeatherIcon,
    ReferralModel,
},
directives: {
    'b-modal': VBModal,
  },
  props: {
    isReferralPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      invitingUserRole,
    }
  },
  emits: ['load-referrals'],
  methods: {
    openReferralModal() {
      this.$bvModal.show('referal-model')
      analytics.track(this.isBrand ? constants.TRACKS.ACTIONS.BRAND_OPENS_INVITE_FORM : constants.TRACKS.ACTIONS.RETAILER_OPENS_INVITE_FORM)
    },
  },
}
</script>
