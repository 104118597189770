<template>
  <svg
    width="78"
    height="69"
    viewBox="0 0 78 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M5.75 1.75C3.54086 1.75 1.75 3.54086 1.75 5.75V51.75C1.75 53.9591 3.54086 55.75 5.75 55.75H42.1836C42.0391 55.0938 41.9297 54.4297 41.8594 53.75H7.75C5.54086 53.75 3.75 51.9591 3.75 49.75V11.2812L32.75 31.4922C35.1484 33.168 38.3516 33.1641 40.75 31.4922L69.75 11.2812V35.0234C70.4453 35.4023 71.1094 35.8281 71.75 36.2852V5.75C71.75 3.54086 69.9591 1.75 67.75 1.75H5.75ZM3.75 7.75C3.75 5.54086 5.54086 3.75 7.75 3.75H65.75C67.9591 3.75 69.75 5.54086 69.75 7.75V8.84375L39.6094 29.8516C37.8906 31.0508 35.6133 31.0508 33.8906 29.8516L3.75 8.84375V7.75Z"
        fill="#EB5757"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.75 2.75C4.09315 2.75 2.75 4.09315 2.75 5.75V7.75C2.75 4.98858 4.98858 2.75 7.75 2.75H5.75ZM0.75 5.75C0.75 2.98858 2.98858 0.75 5.75 0.75H67.75C70.5114 0.75 72.75 2.98858 72.75 5.75V38.227L71.1692 37.0992C70.5553 36.6613 69.9255 36.2579 69.2715 35.9015L68.75 35.6173V13.1971L41.3218 32.3125V32.3125C38.581 34.2231 34.9214 34.2286 32.1782 32.3126M32.1773 32.3119L4.75 13.1971V49.75C4.75 51.4069 6.09315 52.75 7.75 52.75H42.7613L42.8541 53.6471C42.9203 54.2872 43.0234 54.9138 43.1602 55.5349L43.4278 56.75H5.75C2.98858 56.75 0.75 54.5114 0.75 51.75V5.75M67.75 2.75H65.75C68.5114 2.75 70.75 4.98858 70.75 7.75V5.75C70.75 4.09315 69.4069 2.75 67.75 2.75ZM7.75 4.75C6.09315 4.75 4.75 6.09315 4.75 7.75V8.32181L34.462 29.0308C35.8416 29.9913 37.6626 29.9905 39.0372 29.0315L68.75 8.32181V7.75C68.75 6.09315 67.4069 4.75 65.75 4.75H7.75ZM2.75 49.75V51.75C2.75 53.4069 4.09315 54.75 5.75 54.75H7.75C4.98858 54.75 2.75 52.5114 2.75 49.75Z"
        fill="#EB5757"
      />
      <path
        d="M60.75 35.75C51.9258 35.75 44.75 42.9258 44.75 51.75C44.75 60.5742 51.9258 67.75 60.75 67.75C69.5742 67.75 76.75 60.5742 76.75 51.75C76.75 42.9258 69.5742 35.75 60.75 35.75ZM60.75 37.75C68.4922 37.75 74.75 44.0078 74.75 51.75C74.75 59.4922 68.4922 65.75 60.75 65.75C53.0078 65.75 46.75 59.4922 46.75 51.75C46.75 44.0078 53.0078 37.75 60.75 37.75ZM56.457 46.043L55.043 47.457L59.3359 51.75L55.043 56.043L56.457 57.457L60.75 53.1641L65.043 57.457L66.457 56.043L62.1641 51.75L66.457 47.457L65.043 46.043L60.75 50.3359L56.457 46.043Z"
        fill="#EB5757"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.25 51.75C44.25 42.6496 51.6496 35.25 60.75 35.25C69.8504 35.25 77.25 42.6496 77.25 51.75C77.25 60.8504 69.8504 68.25 60.75 68.25C51.6496 68.25 44.25 60.8504 44.25 51.75ZM60.75 36.25C52.2019 36.25 45.25 43.2019 45.25 51.75C45.25 60.2981 52.2019 67.25 60.75 67.25C69.2981 67.25 76.25 60.2981 76.25 51.75C76.25 43.2019 69.2981 36.25 60.75 36.25ZM60.75 38.25C53.284 38.25 47.25 44.284 47.25 51.75C47.25 59.216 53.284 65.25 60.75 65.25C68.216 65.25 74.25 59.216 74.25 51.75C74.25 44.284 68.216 38.25 60.75 38.25ZM46.25 51.75C46.25 43.7317 52.7317 37.25 60.75 37.25C68.7683 37.25 75.25 43.7317 75.25 51.75C75.25 59.7683 68.7683 66.25 60.75 66.25C52.7317 66.25 46.25 59.7683 46.25 51.75ZM56.457 45.3359L60.75 49.6288L65.043 45.3359L67.1641 47.457L62.8712 51.75L67.1641 56.043L65.043 58.1641L60.75 53.8712L56.457 58.1641L54.3359 56.043L58.6288 51.75L54.3359 47.457L56.457 45.3359ZM55.7501 47.457L60.043 51.75L55.7501 56.043L56.457 56.7499L60.75 52.457L65.043 56.7499L65.7499 56.043L61.457 51.75L65.7499 47.457L65.043 46.7501L60.75 51.043L56.457 46.7501L55.7501 47.457Z"
        fill="#EB5757"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ErrorMailIcon',
}
</script>
