<template>
  <b-modal
    id="referral-success-popup"
    centered
    hide-footer
    header-bg-variant="light"
    modal-class="referral-modal modal-add-user"
  >
    <div class="modal-add-user-body mb-2">
      <div class="modal-add-user-header">
        {{ modelHeaderText }}
      </div>
      <div class="elements-center flex-column text-center m-1">
        <post-box-icon />
        <span
          class="font-weight-bolder py-1"
        >Invitation was sent successfully!</span>
        <div>
          {{ succesMessageText }}
          <br>
          <template v-if="isRetailer">
            Track your referrals on the
            <b-link
              class="color-blue"
              :to="{ name: 'referrals' }"
            >
              referral page.
            </b-link>
          </template>
        </div>
      </div>
      <div class="elements-center my-1">
        <template v-if="isBrand">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="$bvModal.hide(REFERRAL_SUCCESS_MODAL_ID)"
          >
            Close
          </b-button>
          <b-button
            variant="custom-primary"
            @click="openCommercialTermsPopup"
          >
            Provide Current terms
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="custom-primary"
            @click="openReferralPopup"
          >
            Invite More Brands
          </b-button>
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
 BModal, BButton, VBModal, BLink,
} from 'bootstrap-vue'
import PostBoxIcon from '@/@core/assets/svg-components/PostBoxIcon.vue'
import UserRoleMixin from '../UserRoleMixin.vue'

const REFERRAL_SUCCESS_MODAL_ID = 'referral_success_modal'

export default {
  components: {
    BModal,
    BButton,
    PostBoxIcon,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      REFERRAL_SUCCESS_MODAL_ID,
    }
  },
  computed: {
    succesMessageText() {
      if (this.isRetailer) {
        return 'We just sent an email to the brand you referred. As soon as brand registers and goes live on kingpin you will start receiving your rewards. '
      }
      return 'Do you already work with this retailer? Please let us know your terms.'
    },
    modelHeaderText() {
      if (this.isRetailer) {
        return 'Invite a Brand and earn up to $5000!'
      }
      return 'Invite a retailer and deal with ZERO commission'
    },
  },
  methods: {
    openCommercialTermsPopup() {
        this.$bvModal.show('referral-commercial-terms-popup')
        this.$bvModal.hide(REFERRAL_SUCCESS_MODAL_ID)
    },
    openReferralPopup() {
      this.$bvModal.hide(REFERRAL_SUCCESS_MODAL_ID)
      this.$bvModal.show('referal-model')
    },
  },
}
</script>

<style></style>
