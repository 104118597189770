<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 73 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.4912 33.4761L44.9528 40.2454M36.4912 33.4761V63.9378V33.4761ZM36.4912 33.4761L28.0297 40.2454L36.4912 33.4761Z"
      stroke="#16ACD9"
      stroke-width="5.3022"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.914 23.7182C9.71285 24.5237 6.91601 26.4704 5.04897 29.1926C3.18192 31.9148 2.37317 35.2251 2.77468 38.5015C3.17618 41.7779 4.76029 44.795 7.22939 46.9858C9.69849 49.1767 12.8826 50.3905 16.1836 50.3992H19.5682"
      stroke="#16ACD9"
      stroke-width="5.3022"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.4544 17.02C48.2976 12.4391 45.4422 8.46952 41.4671 5.91587C37.492 3.36221 32.6946 2.41559 28.0475 3.26788C23.4003 4.12018 19.2511 6.70762 16.4409 10.5057C13.6307 14.3038 12.3699 19.0283 12.9139 23.7215C12.9139 23.7215 13.4318 26.7068 14.4912 28.3991"
      stroke="#16ACD9"
      stroke-width="5.3022"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.4143 50.3993C55.804 50.3976 58.1663 49.8899 60.3456 48.9096C62.5249 47.9292 64.4719 46.4984 66.0584 44.7113C67.6449 42.9242 68.8349 40.8214 69.5502 38.5413C70.2654 36.2612 70.4897 33.8554 70.2081 31.4824C69.9266 29.1093 69.1457 26.8228 67.9168 24.7733C66.6879 22.7238 65.0389 20.9579 63.0783 19.5916C61.1177 18.2254 58.8899 17.2898 56.5417 16.8466C54.1935 16.4033 51.778 16.4625 49.4543 17.02L44.9528 18.2452"
      stroke="#16ACD9"
      stroke-width="5.3022"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
    name: 'UploadIcon',
}
</script>
