<template>
  <div>
    <b-modal
      id="referal-model"
      ref="referalModel"
      modal-class="modal-add-user"
      hide-footer
      centered
      header-bg-variant="light"
      @show="showForm"
    >
      <div class="modal-add-user-body mb-2">
        <div class="modal-add-user-header">
          {{ modelHeaderText }}
        </div>
        <div>
          <div
            v-if="referralWishlistId && !displayError"
            class="toastification-danger mt-50 py-50 px-75"
          >
            The email you entered is not on Kingpin yet. Please provide us more
            details to invite this retailer via your referral link. The
            selection will be available on retailer's wishlist's page once they
            sign up.
          </div>
          <validation-observer
            ref="collectionForm"
            #default="{ invalid }"
          >
            <div
              v-if="displayForm"
              class="mt-1"
            >
              <validation-provider
                #default="{ errors }"
                vid="company-name"
                :name="`${getNameLabel} Name`"
                rules="required|min:3"
              >
                <b-form-group label-class="pb-25">
                  <template
                    #label
                  >
                    {{ getNameLabel }} Name
                    <span class="text-danger">*</span></template>
                  <b-form-input
                    id="company-name"
                    v-model="form.entityName"
                    name="company-name"
                    :placeholder="`${getNameLabel} Name`"
                    required
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger"> {{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                vid="first-name"
                name="First Name"
                rules="required|min:3"
              >
                <b-form-group label-class="pb-25">
                  <template #label>
                    First Name <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="first-name"
                    v-model="form.firstName"
                    name="first-name"
                    placeholder="First Name"
                    required
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                vid="last-name"
                name="Last Name"
                rules="required"
              >
                <b-form-group label-class="pb-25">
                  <template
                    #label
                  >Last Name
                    <span class="text-danger">*</span></template>
                  <b-form-input
                    id="last-name"
                    v-model="form.lastName"
                    name="last-name"
                    placeholder="Last Name"
                    required
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger"> {{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                vid="email"
                name="Email"
                rules="required|email"
              >
                <b-form-group label-class="pb-25">
                  <template
                    #label
                  > Email <span class="text-danger">*</span></template>
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    name="email"
                    placeholder="Email"
                    required
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger"> {{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                vid="phNumber"
                name="Phone Number"
              >
                <b-form-group
                  label="Phone Number"
                  label-class="pb-25"
                >
                  <b-form-input
                    id="phNumber"
                    v-model="form.phNumber"
                    name="phNumber"
                    class="referal-input"
                    type="number"
                    placeholder="Phone Number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger"> {{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                vid="website"
                name="company website"
                rules="min:3"
              >
                <b-form-group
                  label="Company Website"
                  label-class="pb-25"
                >
                  <b-form-input
                    id="website"
                    v-model="form.website"
                    name="website"
                    placeholder="Company Website"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger"> {{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <div
              v-if="displayError"
              class="elements-center flex-column m-1"
            >
              <error-mail-icon />
              <div class="py-1">
                This user was already referred.<br>
                Please enter another contact.
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-end py-1">
              <b-button
                variant="outline-primary"
                @click="onClickInviteAction(inviteAction.CANCEL)"
              >
                Cancel
              </b-button>
              <b-button
                v-if="displayForm"
                variant="custom-primary"
                :disabled="invalid || isLoading"
                class="ml-1"
                @click="onClickInviteAction(inviteAction.SEND)"
              >
                {{
                  isSelection
                    ? isLoading
                      ? 'Sharing'
                      : 'Share'
                    : isLoading
                      ? 'Sending Invite'
                      : 'Send Invite'
                }}
              </b-button>
              <b-button
                v-else
                variant="custom-primary"
                class="ml-1"
                @click="showForm"
              >
                Invite a {{ invitingUserRole() }}
              </b-button>
            </div>
          </validation-observer>
        </div>
      </div>
    </b-modal>
    <referral-mail
      modal-id="referral-personalise-message"
      :referral-data="form"
      :is-selection="isSelection"
      :wishlist="wishlist"
      @load-referrals="loadReferrals"
    />
    <referral-success-popup />
    <referral-commercial-terms :email="form.email" />
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  VBModal,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ErrorMailIcon from '@/@core/assets/svg-components/ErrorMailIcon.vue'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
  formatObject,
  invitingUserRole,
  parseErrorObject,
} from '@/@core/utils/utils'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { apiToastErrorV2 } from '@/@core/utils/toast'
import store from '@/store'
import { VALIDATE_REFERRAL } from '@/store/modules/referral.module'
import ReferralMail from './ReferralMail.vue'
import ReferralCommercialTerms from './ReferralCommercialTerms.vue'
import ReferralSuccessPopup from './ReferralSuccessPopup.vue'
import UserRoleMixinVue from '../UserRoleMixin.vue'

const { ROLES } = c

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    ErrorMailIcon,
    ReferralMail,
    ReferralCommercialTerms,
    ReferralSuccessPopup,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixinVue],
  props: {
    isSelection: {
      type: Boolean,
      default: false,
    },
    referralWishlistId: {
      type: String,
      default: null,
    },
    referralEmail: {
      type: String,
      default: null,
    },
    wishlist: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      form: {
        entityName: '',
        firstName: '',
        lastName: '',
        email: '',
        phNumber: null,
        website: '',
      },
      errMessage: '',
      modelHeadingText: '',
      displayForm: true,
      displayError: false,
      ROLES,
      inviteAction: {
        SEND: 'send invite',
        CANCEL: 'cancel',
        CLOSE: 'close',
      },
      isLoading: false,
      invitingUserRole,
    }
  },
  computed: {
    modelHeaderText() {
      if (this.isSelection) {
        return 'Share Selection'
      }
      if (this.isRetailer) {
        return 'Invite a Brand and earn up to $5000!'
      }
      return 'Invite all your retailers and use Kingpin Direct as your digital order management tool, completely free of charge.'
    },
    getNameLabel() {
      return this.isBrand ? 'Store' : 'Brand'
    },
  },
  watch: {
    referralEmail() {
      this.form.email = this.referralEmail
    },
  },
  emits: ['load-referrals'],
  methods: {
    clearForm() {
      this.form = {
        entityName: '',
        firstName: '',
        lastName: '',
        email: '',
        phNumber: null,
        website: '',
      }
    },
    onClickInviteAction(action) {
      switch (action) {
        case this.inviteAction.SEND:
          this.sendInviteToUser()
          break
        case this.inviteAction.CANCEL:
          this.$bvModal.hide('referal-model')
          break
        case this.inviteAction.CLOSE:
          this.$bvModal.hide('referal-model')
          break
        default:
          break
      }
      analytics.track(
        this.isBrand
          ? constants.TRACKS.ACTIONS.BRAND_SENDS_INVITE
          : constants.TRACKS.ACTIONS.RETAILER_SENDS_INVITE,
        formatObject({ option: action }),
      )
    },
    sendInviteToUser() {
      const payload = {
        email: this.form.email,
      }
      this.isLoading = true
      store
        .dispatch(VALIDATE_REFERRAL, payload)
        .then(() => {
          this.displayError = false
          this.isLoading = false
          this.$bvModal.hide('referal-model')
          this.$bvModal.show('referral-personalise-message')
        })
        .catch(err => {
          this.errMessage = parseErrorObject(err) || 'User is not referred'
          this.isLoading = false
          if (err.response.status === 400) {
            this.displayForm = false
            this.displayError = true
            this.clearForm()
          } else {
            apiToastErrorV2(err)
          }
          analytics.track(
            this.isBrand
              ? constants.TRACKS.ACTIONS.BRAND_INVITE_SENT
              : constants.TRACKS.ACTIONS.RETAILER_INVITE_SENT,
            formatObject({ status: 'Error', errorMessage: this.errMessage }),
          )
        })
    },
    showForm() {
      this.clearForm()
      this.displayForm = true
      this.displayError = false
      this.isLoading = false
    },
    loadReferrals() {
      this.$emit('load-referrals', true)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-referral.scss';
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
</style>
